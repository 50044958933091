/// <reference path="../types/next-auth.d.ts" />
import dayjs from 'dayjs'
import { Session } from 'next-auth'

import type { Config } from './config'

import { Role } from '../const'
import { Claim } from '../const/enum/claim'
import { AllCountriesPostfix, CountryAccessGroup, CountryAccessGroupClaimPrefix } from '../const/enum/country-access-group'

const expiresAt = Date.now() + 7 * 24 * 60 * 60 * 1000 // now + 1 week

const user = {
    name: 'Test User',
    email: 'test.user@engelvoelkers.com',
    image: '/static/images/test-user-avatar.png',
}

const roles = [
    `${CountryAccessGroupClaimPrefix}_${AllCountriesPostfix}`,
    `${CountryAccessGroupClaimPrefix}_${CountryAccessGroup.DACH}_RES`,
    Role.BASIC,
    Role.ADMIN,
]

const DefaultTestUser: Session = {
    email: user.email,
    error: null,
    expires: dayjs(expiresAt).format(),
    expires_at: expiresAt,
    ldap_id: '330ca3bb-2a09-43b7-b6f2-d47ac95affa5',
    roles: roles as Claim[],
    user,
}

export const tests = (): Config['tests'] => ({
    users: {
        default: DefaultTestUser,
    },
})
