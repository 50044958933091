import * as React from 'react'

import { escapeRegExp } from '../utils/regex'

export const HighlightedText: React.FC<{ highlightedText?: string; children: string }> = ({ highlightedText = '', children }) => {
    if (!children.trim()) {
        return <span>{children}</span>
    }

    const regex = new RegExp(`(${escapeRegExp(highlightedText)})`, 'gi')
    const parts = children.split(regex)

    return (
        <span>
            {parts
                .filter((part) => part)
                .map((part, i) => (regex.test(part) ? <strong key={i}>{part}</strong> : <span key={i}>{part}</span>))}
        </span>
    )
}
