import { autocompleteClasses } from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'

export const ListWrapper = styled('ul')(
    () => `
  margin: 0;
  padding: 0;

  list-style: none;


  & li[aria-selected='true'] {
    background-color: #4040401A;;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background: #4040401A;
    cursor: pointer;
  }
`,
)
