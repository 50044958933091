import { Environment } from '../const'

export const getGraphQlHost = (env: Environment) => {
    switch (env) {
        case Environment.local:
            return 'http://localhost:4001'
        case Environment.preview:
            return `https://${process.env.NEXT_PUBLIC_PREVIEW_URL}`
        case Environment.dev:
            if (process.env.NEXT_PUBLIC_LOCAL_GRAPHQL === 'true') {
                return 'http://localhost:4000'
            } else {
                return 'https://lmt.envdevel.com'
            }
        case Environment.stage:
            return 'https://lmt.envstage.com'
        case Environment.prod:
            return 'https://lmt.engelvoelkers.com'
        default:
            throw new Error(`Unknown Environment: ${env}`)
    }
}

export const getGraphQlUrl = (env: Environment) => {
    const host = getGraphQlHost(env)

    switch (env) {
        case Environment.local:
        case Environment.preview:
        case Environment.dev:
        case Environment.stage:
        case Environment.prod:
            return `${host}/api/graphql`
        default:
            throw new Error(`Unknown Environment: ${env}`)
    }
}

export const graphql = (env: Environment) => ({
    uri: getGraphQlUrl(env),
    credentials: env !== Environment.local ? 'include' : undefined,
})
