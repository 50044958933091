import { useTranslation } from '@/lib/i18n'
import { Typography, UseAutocompleteRenderedOption } from '@mui/material'

import { TransformedOption } from './transformed-option'
import { AutocompleteItem } from './types'

export const CustomizedAutocompleteItem: React.FC<{
    licenceAreas: AutocompleteItem[]
    googlePlaces: AutocompleteItem[]
    getOptionProps: (renderedOption: UseAutocompleteRenderedOption<AutocompleteItem>) => React.HTMLAttributes<HTMLLIElement>
    searchTerm?: string
}> = ({ licenceAreas, googlePlaces, getOptionProps, searchTerm }) => {
    const { t } = useTranslation('common')

    return (
        <>
            {licenceAreas.length > 0 ? (
                <>
                    <Typography variant="body4" color="dark.lighter" padding={1}>
                        {t('licenceArea.search.evResults')}
                    </Typography>
                    {licenceAreas.map((option, index) => (
                        // NOTE: Keep key={option.id} on the right so that it overrides getOptionProps
                        <li {...getOptionProps({ option, index })} key={option.id}>
                            <TransformedOption option={option} searchTerm={searchTerm} />
                        </li>
                    ))}
                </>
            ) : null}
            {googlePlaces.length > 0 ? (
                <>
                    <Typography variant="body4" color="dark.lighter" padding={1}>
                        {t('licenceArea.search.googleResults')}
                    </Typography>
                    {googlePlaces.map((option, index) => (
                        // NOTE: Keep key={option.id} on the right so that it overrides getOptionProps
                        // Set the index to be after all licenceAreas list items. Otherwise, the index would reset to 0
                        <li {...getOptionProps({ option, index: index + licenceAreas.length })} key={option.id}>
                            <TransformedOption option={option} searchTerm={searchTerm} />
                        </li>
                    ))}
                </>
            ) : null}
        </>
    )
}
