import { api } from './api'
import { Config } from './config'
import './dayjs'
// NOTE: keep imported
import { environment } from './environment'
import { googleMaps } from './google-maps'
import { graphql } from './graphql'
import { keycloak } from './keycloak'
import { tests } from './tests'

const getConfig = (): Config => {
    const env = environment()
    const version = process.env.NEXT_PUBLIC_CI_COMMIT_SHA

    if (!version) {
        throw new Error('NEXT_PUBLIC_CI_COMMIT_SHA is not defined')
    }

    return {
        api: api(env),
        env,
        googleMaps: googleMaps(),
        graphql: graphql(env),
        keycloak: keycloak(env),
        tests: tests(),
        version,
    }
}

const config = getConfig()

export default config
