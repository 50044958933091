import { Environment } from '../const'

export const getApiHost = (env: Environment) => {
    switch (env) {
        case Environment.local:
            return 'http://localhost:4001'
        case Environment.preview:
        case Environment.dev:
            if (process.env.NEXT_PUBLIC_LOCAL_BACKEND === 'true') {
                return 'http://localhost:8080'
            } else {
                return `https://master-data-service.envdevel.com`
            }
        case Environment.stage:
            return `https://master-data-service.envstage.com`
        case Environment.prod:
            return `https://master-data-service.engelvoelkers.com`
        default:
            throw new Error(`Unknown Environment: ${env}`)
    }
}

export const getApiUrl = (env: Environment) => {
    const host = getApiHost(env)

    switch (env) {
        case Environment.local:
        case Environment.dev:
        case Environment.preview:
        case Environment.stage:
        case Environment.prod:
            return `${host}/api/graphql`
        default:
            throw new Error(`Unknown Environment: ${env}`)
    }
}

export const api = (env: Environment) => ({
    uri: getApiUrl(env),
    credentials: env !== Environment.local ? 'include' : undefined,
})
