import { Config } from './config'

import { Environment } from '../const'

const getKeycloakIssuer = (env: Environment) => {
    switch (env) {
        case Environment.local:
        case Environment.preview:
        case Environment.dev:
            return 'https://keycloak-login.envdevel.com/realms/ev-tech'
        case Environment.stage:
            return 'https://keycloak-login.envstage.com/realms/ev-tech'
        case Environment.prod:
            return 'https://login.engelvoelkers.com/realms/ev-tech'
        default:
            throw new Error(`Unknown Environment: ${env}`)
    }
}

export const keycloak = (env: Environment): Config['keycloak'] => ({
    clientId: process.env.KEYCLOAK_CLIENT_ID || '',
    clientSecret: process.env.KEYCLOAK_CLIENT_SECRET || '',
    issuer: getKeycloakIssuer(env),
})
