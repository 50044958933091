import { useTranslation } from '@/lib/i18n'

import { AutocompleteOption } from './autocomplete-option'
import { AutocompleteItem } from './types'

import { LegalEntityIcon, LicenceContractIcon, PinIcon, ShopIcon } from '../icons'

import { licenceContractName } from '../../utils/formatting'
import { isSet } from '../../utils/primitives'

type TransformedOption = {
    option: AutocompleteItem
    searchTerm?: string
}

export const TransformedOption: React.FC<TransformedOption> = ({ option, searchTerm }) => {
    const { t } = useTranslation('common')

    const transformOption = (option: AutocompleteItem) => {
        let icon: React.ReactNode = null
        let text = ''
        let subtexts: string[] = []

        const { legalEntity, licenceContract, shop, isGooglePlace } = option

        if (legalEntity) {
            icon = <LegalEntityIcon sx={{ width: 24, height: 24 }} />
            text = legalEntity.name
            subtexts = [legalEntity.physicalAddress?.countryA3, legalEntity.debtorNumber, legalEntity.licenseeRoleStatus].filter(isSet)
        }

        if (licenceContract) {
            icon = <LicenceContractIcon sx={{ width: 24, height: 24 }} />
            text = licenceContractName(licenceContract)

            if (licenceContract.businessUnit) {
                subtexts.push(t(`enum.BusinessUnit.${licenceContract.businessUnit}`))
            }

            if (licenceContract.contractType) {
                subtexts.push(t(`enum.ContractType.${licenceContract.contractType}`))
            }

            subtexts.push(t(`enum.LicenceContractStatus.${licenceContract.status}`))
        }

        if (shop) {
            icon = <ShopIcon sx={{ width: 24, height: 24 }} />
            text = shop.name

            if (shop.physicalAddress?.countryA3) {
                subtexts.push(shop.physicalAddress?.countryA3)
            }

            subtexts.push(shop.status)

            if (shop.licenceArea?.licenceContract?.businessUnit) {
                subtexts.push(t(`enum.BusinessUnit.${shop.licenceArea.licenceContract.businessUnit}`))
            }
        }

        if (isGooglePlace) {
            icon = <PinIcon sx={{ width: 24, height: 24 }} />
            text = option.label
        }

        return {
            id: option.id,
            label: option.label,
            icon,
            text,
            subtexts,
        }
    }

    return <AutocompleteOption option={transformOption(option)} searchTerm={searchTerm} />
}
