import { Config } from './config'

export const googleMaps = (): Config['googleMaps'] => {
    const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
    const mapId = process.env.NEXT_PUBLIC_GOOGLE_MAPS_ID

    if (!apiKey) {
        throw new Error('No NEXT_PUBLIC_GOOGLE_MAPS_API_KEY set...')
    }

    if (!mapId) {
        throw new Error('No NEXT_PUBLIC_GOOGLE_MAPS_ID set...')
    }

    return {
        apiKey,
        mapId,
    }
}
