import { styled } from '@mui/material/styles'

export const ListBox = styled('div')<{ width: number }>(
    ({ theme, width }) => `
  position: absolute;

  max-height: 80vh;

  width: ${width}px;

  overflow: auto;

  border-radius: 4px;
  box-shadow: ${theme.shadows[8]};
  background-color: white;

  & svg {
      color: ${theme.palette.dark.simple};
    }

  z-index: 1;
`,
)
