'use client'

import { styled } from '@mui/material/styles'

export const Center = styled('div', { name: 'Center' })({
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})
